export const environment = {
  production: true,
  hideElttoUi: true,
  apiUrl: 'https://auth-blue2.mountainseedapp.com/api',
  elttoUrl: 'https://eltto-blue2.mountainseedapp.com/',
  miUrl: 'https://analytics-blue2.mountainseedapp.com/',
  ngxs: {
    plugins: [],
  },
};
